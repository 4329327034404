import { h } from "preact";
import { Router } from "preact-router";
import "./App.css";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Blog from "../routes/blog";
import Article from "../routes/article";
import Imprint from "../routes/imprint";
import Projects from "../routes/projects";
import Project from "../routes/project";
import Navbar from "./navbar";

import { adaptNavbarColor } from "../utils";
import { store } from "../globalState";

const initialState = {
  articles: [],
  articlesMarkdown: {},
  projects: [],
};

store.init(initialState);

const handleRoute = (e) => {
  if (typeof window !== "undefined") {
    const { url } = e;
    adaptNavbarColor(url);
  }
};

const App = () => (
  <div id="app">
    <Navbar />
    <Router onChange={handleRoute}>
      <Home path="/" />
      <Blog path="/blog" />
      <Projects path="projects" />
      <Project path="/project/:id" />
      <Article path="/blog/:id" />
      <Imprint path="/imprint" />
    </Router>
  </div>
);

export default App;
