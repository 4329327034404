import { useState } from "preact/hooks";

/*
Simple global state store that currently only works for single components subscribed to one entry.
It's purpose is to cache api requests. 
*/

class Store {
  constructor() {
    this.state = {};
  }

  init(initialState) {
    this.state = initialState;
  }

  getState(key) {
    return this.state[key];
  }

  setState(key, value) {
    this.state[key] = value;
  }
}

const useGlobalState = (key) => {
  const state = store.getState(key);

  const [, setStatePre] = useState(state);

  const setState = (val) => {
    setStatePre(val);
    store.setState(key, val);
  };

  return [state, setState];
};

const store = new Store();

export { store, useGlobalState };
