import { h } from "preact";

import { Link } from "preact-router/match";
import style from "./style.css";

import { useEffect } from "preact/hooks";
import { adaptNavbarColor } from "../../utils";

const Navbar = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      adaptNavbarColor(window.location.pathname);
    }
  }, []);
  return (
    <div id="navbar" class={style.navbar_container}>
      <nav>
        <Link href="/" activeClassName={style.active}>
          Home
        </Link>
        <Link href="/blog" activeClassName={style.active}>
          Blog
        </Link>
        <Link href="/projects" activeClassName={style.active}>
          Projects
        </Link>
      </nav>
    </div>
  );
};

export default Navbar;
