export const adaptNavbarColor = (url) => {
  const navbar = document.getElementById("navbar");
  if (navbar) {
    if (url === "/projects") {
      navbar.style.backgroundColor = "#202021";
    } else {
      navbar.style.backgroundColor = "black";
    }
  }
};
